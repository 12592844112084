import React from 'react';
import './GenInfo.css';
import { Container, Row, Col, Table } from 'react-bootstrap';

const faqs = [{
  "question": "Where can I find general ARC WEPTAC information?",
  "answer": "On this website. Please note: this website will be updated regularly as more information becomes available."
},
{
  "question": "What are the different mission working groups?",
  "answer": "The list is available on this website under “Working Groups” tab."
},
{
  "question": "Do I have to register?",
  "answer": "Yes, if you are requesting to attend: an ON-SITE working group, Spectrum Warfare Day, the Welcome In-brief, or the Executive Outbrief. You also need to register if you are a local/AATC personnel participating in WEPTAC, a Working Group Chair (on-site, off-site, or MAWG), or RSVP-ing “yes” to the Afterburner Party"
},
{
  "question": "Where do I have to register?",
  "answer": "Links to submitting a request to attend are on the homepage of this website."
},
{
  "question": "How do I know if my registration has been approved?",
  "answer": "You will need to receive an “approved to attend” email from noreply_weptac_registration@us.af.mil (verbal or any other form of approval will not be accepted as an approval)."
},
{
  "question": "Are all ranks allowed in the MWS working groups?",
  "answer": "Only O-5 and below as well as Civilians and Government/DoD Contractors are allowed. O-6s, General Officers, SESs and GS-15s are not allowed to attend any MWS working group. This will be strictly enforced."
},
{
  "question": "Will there be an online participation option for this year's WEPTAC?",
  "answer": "Each Working Group Chair develops their own agenda, to include the meeting platform. Almost all of the MWS working groups will be meeting in-person, in Tucson. Some will meet at their home base prior to WEPTAC. All of the MAWGs will meet either virtually or at a location of their choosing prior to WEPTAC. The list is available on this website under “Working Groups” tab. Please contact the Chair for the working group(s) you’re interested in attending to understand where/when/how they’ll conduct their groups. Currently, all other portions of WEPTAC (i.e., opening brief) will be held in-person without a virtual option."
},
{
  "question": "How is my security clearance vetted? Do I need to submit a SMO Code?",
  "answer": "The information you provide in your registration request submission will be used to check your security clearance. Unless you are participating in a working group held in a SCIF or SAPF, you do not need to submit a SMO Code/any additional information for attendance. If you are attending one of these working groups, you will receive further instruction from the Chair and/or an AATC representative."
},
{
  "question": "I’m a Colonel, what events can I attend?",
  "answer": "The golf tournament (Sunday), The Welcome In-brief (Monday), The Industry Nights (Monday & Tuesday), The Afterburner Party (Wednesday), The Executive Outbrief (Friday), **See the above agenda for times & locations"
},
{
  "question": "I’m a General Officer, SES or GS-15, what events can I attend?",
  "answer": "The golf tournament (Sunday), The Welcome In-brief (Monday), The Industry Nights (Monday & Tuesday), The Afterburner Party (Wednesday), DV Day (Thursday), The Executive Outbrief (Friday), **See the above agenda for times & locations"
},
{
  "question": "I’m a general WEPTAC attendee, can I attend the Executive Outbrief?",
  "answer": "Only if you have received an invitation from AATC Commander."
},
{
  "question": "Can industry partners attend the mass briefs, working groups and/or the Afterburner Party?",
  "answer": "No. Only government personnel are allowed. Contractors with displays at Industry Nights are NOT allowed."
},
{
  "question": "How do industry partners provide proposed briefings?",
  "answer": "A vendor may get a military member to brief/relay material IF absolutely necessary."
},
{
  "question": "What are the dates for the Industry Nights?",
  "answer": "Monday, 21 October and Tuesday, 22 October from 1700 to 2100."
},
{
  "question": "Who coordinates the Industry Nights and how does a Vendor sign up?",
  "answer": "The host hotel – the Hilton DoubleTree Tucson Airport. Vendors interested in participating in Industry Night need to directly contact the DoubleTree hotel to coordinate their exhibit space: (520) 225-0810. "
},
{
  "question": "Will there be a Spectrum Warfare Day (SWD) this year?",
  "answer": "Yes, SWD will be held prior to WEPTAC, on FRIDAY 18 October. Topics will include over-the-air reprograming, angry/mad kitten developments, cognitive EW, and open systems architecture. Select the “Ranks O-5 and below, CIVs &amp; Gov CTRs Request to Attend…” link on the homepage to submit a registration request and be sure to click “yes” to the SWD question.  SWD Check-in/badge pick-up information will be provided in the approval email. NOTE: the SWD badge will NOT get you into any other WEPTAC event/working group. Approved WEPTAC attendees will need a separate badge."
},
{
  "question": "Will there be a golf tournament this year?",
  "answer": "Yes! A four-person scramble (assemble your own or sign up as a single and we’ll add you to a team) will be held on Sunday, 20 October (0630 Check-in) at the Quarry Pines Golf Club. Email SMSgt John Carney (john.carney.1@us.af.mil) to sign up. The cost is $85 per player; select the “2024 ARC WEPTAC Golf Tournament” link on the homepage of this website to access the Venmo QR code to pay."
}
]

export default class GenInfo extends React.Component {
  render() {
    return (
      <Container  >
        <Row className='agenda-section'>
          <Col lg={12}>
            <h2> 2024 Air Reserve Component (ARC) Weapons and Tactics Conference (WEPTAC)</h2>
            <h3></h3>
            <h1 className='titles'> <font color='#2e78a6'>21-25 October 2024</font></h1>
            <h2 className='subtitles'> The Air National Guard (ANG) and Air Force Reserve Command (AFRC) Test Center (AATC) will host the annual ARC WEPTAC in Tucson, Arizona the week of 21 October 2024.</h2>
            <p className='agenda-text'>Since 1999, AATC has hosted the ARC WEPTAC each October in Tucson, Arizona with the primary objective of identifying critical capability shortfalls for ARC weapons systems, missions, and training priorities. Representatives from both the ANG and AFRC field units work through mission weapon system (MWS) working groups to partake in cross-functional discussions with shared mission units and supporting staffs. </p>

            <h2 className='subtitles'><font color='#2e78a6'>ARC WEPTAC Attendance: is by approval only and is government personnel only.</font></h2>
            <div className="higlight">GENERAL ATTENDANCE: ARC WEPTAC working group attendance is for <i>Ranks O-5 and below, and DoD/Government Civilians/Contractors only</i>.</div>
            {/* <p className='agenda-text'> Those not on the approved list will not be badged or allowed into any breakout. Each MWS Chair has been allotted a specific number of attendee slots.AATC/local attendees: will need to submit a registration request (see instructions under "Resources" tab").</p> */}
            <ul name="at">
              <li> The 120, 60 &amp; 30- Day Messages are NOT an invitation to attend. Attendee representation at WEPTAC will be restricted to those ensuring mission priorities are presented during the working group and will be at the discretion of the MWS Chair.</li>
              <li> Spectrum Warfare Day will be held prior to WEPTAC this year, on <u>FRIDAY 18 October</u>. Topics will include over-the-air reprograming, angry/mad kitten developments, cognitive EW, and open systems architecture. Select the “Ranks O-5 and below, CIVs &amp; Gov CTRs Request to Attend…” link on the homepage to submit a registration request and be sure to click “yes” to the SWD question.  SWD Check-in/badge pick-up information will be provided in the approval email. NOTE: the SWD badge will NOT get you into any other WEPTAC event/working group. Approved WEPTAC attendees will need a separate badge.</li>

              <li> View the list of working groups on the "Working Groups" tab above</li>

             <ul>
                <li>If your MWS is NOT meeting on-site, you’ll need to contact your MWS Working Group Chair to determine when & where your MWS working group is meeting (note: these will be meeting PRIOR to the week of WEPTAC).</li>
                <li>If your MWS IS meeting on-site, contact the applicable Working Group Chair(s) to obtain their approval to attend. </li>

              <ul>
              <li>Each on-site working group has been allotted a specific number of max attendee spots.  </li>
              <li>Those not on the approved list will not be badged or allowed into any breakout.  </li>
              <li>Click the “Ranks O-5 and below, CIVs &amp; Gov CTRs Request to Attend…” link on the homepage to submit a registration request. <u>The deadline to submit is 30 September (if attending a SAP/TS working group, the deadline is 23 August)</u>.  </li>
              <li>Select the primary working group you’ll be supporting. If you need to participate in more than one working group, please work directly with the Working Group Chairs. </li>


               </ul>

              </ul>

            </ul>
            <div className="higlight">GENERAL OFFICER, SES, GS-15 &amp; COLONEL ATTENDANCE: WEPTAC attendance is by approval or invitation only. </div>
            {/* <p className='agenda-text'> Those not on the approved list will not be badged or allowed into any breakout. Each MWS Chair has been allotted a specific number of attendee slots.</p> */}
            <ul name="at">
              <li> <i>Colonels</i> will submit a request to attend and will receive approval from the AATC Commander. Select the “Colonels / Rank O-6 Request to Attend…” on the homepage. <u>The deadline to submit a registration request is 30 September.</u> </li>
              <li> Only <i>General Officers, SESs and GS-15s</i> will receive an invitation from the AATC Commander. The 120, 60 &amp; 30- Day Messages are NOT an invitation to attend.</li>
              <li> See the FAQs below for what events you can attend (only Ranks O-5 and below,DoD/Gov Civilians/Contractors are allowed in the working groups).</li>
            </ul>

            <div className="higlight">ALL ATTENDEES:</div>
            {/* <p className='agenda-text'> Those not on the approved list will not be badged or allowed into any breakout. Each MWS Chair has been allotted a specific number of attendee slots.</p> */}
            <ul name="at">
            {/*  <li> REGISTRATION IS CURRENTLY CLOSED. A separate correspondence will be sent with details and instructions once it opens (approx. 60 days prior to WEPTAC).</li> */}
              <li> Registration is only required for on-site working groups, SWD, all Chairs, and Colonels.</li>
              <li> Your request to attend will be reviewed by the respective group coordinators regularly; please be patient!</li>


              <ul>
                <li> You will receive an automated email reply based on their decision (approved/not approved). Make sure to check your spam/junk email.</li>
                <li> Your status will remain pending until an approved/denied email is received.</li>
                <li> You are not officially approved until you receive an “approved to attend” email from noreply_weptac_registration@us.af.mil (verbal or any other form of approval will not be accepted).</li>
              </ul>
                <li> Final arbitration for unresolved issues will be addressed by the AATC/CC.</li>
                <li> There will be a non-refundable landing fee (and an Afterburner Party fee due if you RSVP’d yes) when you pick up your badge. </li>
                <li> Great news hackers and duffers, we’re resurrecting the WEPTAC golf tournament for 2024! Don’t miss out on this exciting kick off to the WEPTAC week! See the FAQs below for more information. </li>

            </ul>
            <br/>
           {/*  <h2 id="registrationSection" className='subtitles'><font color='#2e78a6'> Request to Attend/Registration*** </font></h2>
            <div className="higlight"><font color='red'><u>REGISTRATION IS NOT YET OPEN</u></font></div>
            <br/>
            <p className='agenda-text'>***for ON-SITE WORKING GROUPS ONLY. Please review the list of working groups meeting on-site vs. off-site on the “Working Groups” tab. Contact the off-site working group chair for meeting details.</p>
            <ul>
              <li>Links to submit a request to attend will be on the ARC WEPTAC homepage</li>
              <ul>
                <li>one link is for Colonels/O-6s</li>
                <li>one link is for Ranks O-5 &amp; below, Civilians and Government Contractors</li>
                <li>instructions on how to submit a request to attend are under the “Resources” tab</li>
              </ul>
              <li>You receive a “You’re approved to attend XYZ working group” (one email per request if you submitted a registration request for multiple working groups). Your status will be pending until an approved/denied email is received; therefore, make sure to check your spam/junk email. </li>
              <li>Additional information, i.e. travel details, will be provided in your “approved to attend” email. <u>Please do not make any travel arrangements prior to receiving this authorization email.</u></li>
              </ul>*/}

            <br/>
            <div><i>Industry Partners</i>: all industry partners interested in exhibiting at the two Industry Nights (21 & 22 October) need to contact the host location directly (Hilton DoubleTree Hotel-Tucson Airport | (520) 225-0810. <u>Please note: the Industry Nights are the only event industry partners will be allowed to participate in (WEPTAC working groups and the Afterburner Party are strictly for Gov/DoD personnel).</u></div>
          </Col>
        </Row>
        <Row className='agenda-section'>
          <Col lg={12} className='save-the-date'>

            <h1 className='titles'>Agenda Overview<font size='3'> *subject to change</font></h1>

            <h2 className='subtitles'>18 October (Friday)</h2>
                        <Table>
                          <tbody>
                            <tr>
                              <td style={{ width: '150px' }}>
                                <p className='agenda-text'> <font color="#2e78a6">0800-1600</font></p>
                              </td>
                              <td>
                                <p className='agenda-text'> Spectrum Warfare Day - Morris ANG</p>
                              </td>
                            </tr>
                          </tbody>
                        </Table>


            <h2 className='subtitles'>20 October (Sunday)</h2>
            <Table>
              <tbody>
                <tr>
                  <td style={{ width: '150px' }}>
                    <p className='agenda-text'> <font color="#2e78a6">0630</font></p>
                  </td>
                  <td>
                    <p className='agenda-text'> Golf Tournament - Quarry Pines Golf Club</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '150px' }}>
                    <p className='agenda-text'> <font color="#2e78a6">1200 - 2100</font> </p>
                  </td>
                  <td>
                    <p className='agenda-text'> Registration/Badge pick-up – Hilton DoubleTree, Tucson Airport (<i>general attendees only; CSS will inform DVs where to pick up their badges</i>)</p>
                  </td>
                  </tr>
              </tbody>
            </Table>

            <h2 className='subtitles'>21 October (Monday)</h2>
            <Table>
              <tbody>
                <tr>
                  <td style={{ width: '150px' }}>
                    <p className='agenda-text'> <font color="#2e78a6">0630 - 0900</font> </p>
                  </td>
                  <td>
                    <p className='agenda-text'> Registration/Badge pick-up – DMAFB Theater </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '150px' }}>
                    <p className='agenda-text'> <font color="#2e78a6">1000 - 1600</font> </p>
                  </td>
                  <td>
                    <p className='agenda-text'> Registration/Badge pick-up – Morris ANGB, Bldg. 10 / AATC</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '150px' }}>
                    <p className='agenda-text'> <font color="#2e78a6">0730 - 1100</font> </p>
                  </td>
                  <td>
                    <p className='agenda-text'>Mass Opening Briefs and Keynote Speaker – DMAFB Theatre</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '150px' }}>
                    <p className='agenda-text'> <font color="#2e78a6">1200 - 1700</font> </p>
                  </td>
                  <td>
                    <p className='agenda-text'> MWS working group breakouts <i>(Ranks O-5 &amp; below, CIVs & Govn’t/DoD CTRs Only)</i> </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '150px' }}>
                    <p className='agenda-text'> <font color="#2e78a6">1700 - 2100</font> </p>
                  </td>
                  <td>
                    <p className='agenda-text'>Industry Night – Hilton DoubleTree, Tucson Airport </p>
                  </td>
                </tr>

              </tbody>
            </Table>

            <h2 className='subtitles'>22 October (Tuesday)</h2>
            <Table>
              <tbody>
                <tr>
                  <td style={{ width: '150px' }}>
                    <p className='agenda-text'> <font color="#2e78a6">0730 - 1600</font> </p>
                  </td>
                  <td>
                    <p className='agenda-text'> Registration/Badge pick-up – Morris ANGB, Bldg. 10 / AATC</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '150px' }}>
                    <p className='agenda-text'> <font color="#2e78a6">0800 - 1700</font> </p>
                  </td>
                  <td>
                    <p className='agenda-text'> MWS working group breakouts <i>(Ranks O-5 &amp; below, CIVs & Govn’t/DoD CTRs Only)</i> </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '150px' }}>
                    <p className='agenda-text'> <font color="#2e78a6">1700 - 2100</font> </p>
                  </td>
                  <td>
                    <p className='agenda-text'> Industry Night – Hilton DoubleTree, Tucson Airport </p>
                  </td>
                </tr>
              </tbody>
            </Table>

            <h2 className='subtitles'>23 October (Wednesday)</h2>
            <Table>
              <tbody>
                <tr>
                  <td style={{ width: '150px' }}>
                    <p className='agenda-text'> <font color="#2e78a6">0730 - 1200</font> </p>
                  </td>
                  <td >
                    <p className='agenda-text'> Registration/Badge pick-up – Morris ANGB, Bldg. 10 / AATC</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '150px' }}>
                    <p className='agenda-text'> <font color="#2e78a6">0800 - 1700</font> </p>
                  </td>
                  <td>
                    <p className='agenda-text'> MWS working group breakouts <i>(Ranks O-5 &amp; below, CIVs & Govn’t/DoD CTRs Only)</i> </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '150px' }}>
                    <p className='agenda-text'> <font color="#2e78a6">1700 - 2100</font> </p>
                  </td>
                  <td>
                    <p className='agenda-text'> Afterburner Party – Morris ANGB, Hangar 10 <i>(Gov/DoD Personnel & families of Only)</i> </p>
                  </td>
                </tr>
              </tbody>
            </Table>

            <h2 className='subtitles'>24 October (Thursday)</h2>
            <Table>
              <tbody>
                <tr>
                  <td style={{ width: '150px' }}>
                    <p className='agenda-text'> <font color="#2e78a6">0730 - 1630</font> </p>
                  </td>
                  <td>
                    <p className='agenda-text'> Executive Outbrief Practice Run <i>(MWS/MAWG Chairs & WEPTAC Support Staff Only)</i> </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '150px' }}>
                    <p className='agenda-text'> <font color="#2e78a6">1300 - 2100</font> </p>
                  </td>
                  <td>
                    <p className='agenda-text'> DV Day <i>(Invitation Only)</i> </p>
                  </td>
                </tr>
              </tbody>
            </Table>

            <h2 className='subtitles'>25 October (Friday)</h2>
            <Table>
              <tbody>
                <tr>
                  <td style={{ width: '150px' }}>
                    <p className='agenda-text'> <font color="#2e78a6">0700 - 1500</font> </p>
                  </td>
                  <td>
                    <p className='agenda-text'> Executive Outbrief – Morris ANGB, Bldg. 40 <i>(Invitation Only)</i> </p>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>

        <Row className='agenda-section'>
          <h1 className='titles'>FAQs</h1>

          {faqs.map(({ question, answer }) =>
            <details>
              <summary><font color='#5c5c5c'>&ensp;{question}</font></summary>
              <p>{answer}</p>
            </details>)
          }

        </Row>
        <br />
        <br />
      </Container>
    );
  }
}
