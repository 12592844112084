import React from 'react';
import './Resources.css';
import { Container, Row, Col } from 'react-bootstrap';

export default class Resources extends React.Component {
    render() {
    return (
        <Container className='contact-info'>
            <Row className='justify-content-center'>
                <Col className='resources' lg={12}>
                <Col lg={12}><h1 className='titles mb-4'> Resources </h1></Col>
                <h3 className='subtitles m-0 p-0 pb-2 pt-2'>Hotel Room Blocks:</h3>
                <li><a id="roomBlocks" href="/docs/Room Block_2024_FOR WEBSITE.xlsx" target="_blank"> Click here to view the block of hotel rooms available at government per diem </a></li>
                </Col>
            </Row>
        </Container>
    );
}}
